import { navigate } from "gatsby-link";
import * as React from "react";
import { useState } from 'react';


import * as contactStyles from '../styles/contact.module.css';

import Layout from '../components/layout';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [honey, setHoney] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault()
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": event.target.getAttribute("name"),
            name,
            email,
            message,
            "bot-field": honey
          })
        }).then(() => navigate("/thank-you/")).catch(error => alert(error))
    }

    function encode(data) {
        console.log(data);
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    return (
        <Layout>
            <section className={contactStyles.intro}>
                <h1 className={contactStyles.title}>Contact</h1>
                <p className={contactStyles.description}>I am currently taking commission work. If you would like more information, or are inquiring about an art piece you want, you can contact me at andreartvegas@gmail.com or leave me a message here! Thank you for your continued support! - Andre</p>
            </section>
            <section className={contactStyles.formSection}>
            <div id="contact-form" className={contactStyles.contactFormWrapper}>
                <h2 className={contactStyles.contactFormTitle}>Leave a Message</h2>
                <form
                name="Contact Form"
                data-netlify="true"
                netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
                >
                <input type="hidden" name="form-name" value="Contact Form" />
                <div className={contactStyles.formGroup}>
                    <label className={contactStyles.formLabel} htmlFor="name">Name</label>
                    <input
                    type="text"
                    className={contactStyles.formInput} 
                    name="name" 
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                    />
                </div>
                <div className={contactStyles.formGroup}>
                    <label className={contactStyles.formLabel} htmlFor="email">Email</label>
                    <input
                    type="email"
                    className={contactStyles.formInput} 
                    name="email" 
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    />
                </div>
                <div className={contactStyles.formGroup}>
                    <label className={contactStyles.formLabel} htmlFor="comments">Message</label>
                    <textarea 
                    id="comments" 
                    rows="4" 
                    className={contactStyles.formTextarea} 
                    name="message" 
                    placeholder="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    required
                    ></textarea>
                </div>
                {/* <div data-netlify-recaptcha="true"></div> */}
                <input
                    type="hidden"
                    name="bot-field"
                    onChange={(e) => setHoney(e.target.value)}
                />
                <button type="submit" className={contactStyles.formBtn}>Submit</button>
                </form>
                </div>
            </section>
        </Layout>
    )
}

export default Contact;