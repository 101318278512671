// extracted by mini-css-extract-plugin
export var intro = "contact-module--intro--xh0Cz";
export var title = "contact-module--title--kShq8";
export var description = "contact-module--description--utPNE";
export var formSection = "contact-module--form-section--Hj88y";
export var contactFormWrapper = "contact-module--contact-form-wrapper--W69CP";
export var contactFormTitle = "contact-module--contact-form-title--t7RLs";
export var formGroup = "contact-module--form-group--HrSJE";
export var formLabel = "contact-module--form-label--+wtWK";
export var formInput = "contact-module--form-input--nDqc+";
export var formTextarea = "contact-module--form-textarea---8fSM";
export var formBtn = "contact-module--form-btn--D65mX";